import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="combat"></a><h2>Combat</h2>
    <a id="combat-modifiers"></a><h3>COMBAT MODIFIERS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <a id="favorable-and-unfavorable-conditions"></a><h5>FAVORABLE AND UNFAVORABLE CONDITIONS</h5>
    <a id="table-attack-roll-modifiers"></a><p><b>Table: Attack Roll Modifiers</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "500px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "350px"
          }}>Attacker is . . .</th>
          <th style={{
            "width": "75px"
          }}>Melee</th>
          <th style={{
            "width": "75px"
          }}>Ranged</th>
        </tr>
        <tr className="odd-row">
          <td>Dazzled</td>
          <td>&#8211;1</td>
          <td>&#8211;1</td>
        </tr>
        <tr>
          <td>Entangled</td>
          <td>&#8211;2<sup>1</sup></td>
          <td>&#8211;2<sup>1</sup></td>
        </tr>
        <tr className="odd-row">
          <td>Flanking defender</td>
          <td>+2</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>Invisible</td>
          <td>+2<sup>2</sup></td>
          <td>+2<sup>2</sup></td>
        </tr>
        <tr className="odd-row">
          <td>On higher ground</td>
          <td>+1</td>
          <td>+0</td>
        </tr>
        <tr>
          <td>Prone</td>
          <td>&#8211;4</td>
          <td>&#8212;<sup>3</sup></td>
        </tr>
        <tr className="odd-row">
          <td>Shaken or frightened</td>
          <td>&#8211;2</td>
          <td>&#8211;2</td>
        </tr>
        <tr>
          <td className="last-row">Squeezing through a space</td>
          <td className="last-row">&#8211;4</td>
          <td className="last-row">&#8211;4</td>
        </tr>
        <tr>
          <td colSpan="3">1 <i>An entangled character also takes a
&#8211;4 penalty to Dexterity, which may affect his attack roll.</i></td>
        </tr>
        <tr>
          <td colSpan="3">2 <i>The defender loses any Dexterity
bonus to AC. This bonus doesn&#8217;t apply if the target is blinded.</i></td>
        </tr>
        <tr>
          <td colSpan="3">3 <i>Most ranged weapons can&#8217;t be used
while the attacker is prone, but you can use a crossbow or shuriken
while prone at no penalty.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="table-armor-class-modifiers"></a><p><b>Table: Armor Class Modifiers</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "500px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "350px"
          }}>Defender is . . .</th>
          <th style={{
            "width": "75px"
          }}>Melee</th>
          <th style={{
            "width": "75px"
          }}>Ranged</th>
        </tr>
        <tr className="odd-row">
          <td>Behind cover</td>
          <td>+4</td>
          <td>+4</td>
        </tr>
        <tr>
          <td>Blinded</td>
          <td>&#8211;2<sup>1</sup></td>
          <td>&#8211;2<sup>1</sup></td>
        </tr>
        <tr className="odd-row">
          <td>Concealed or invisible</td>
          <td colSpan="2">&#8212; See Concealment &#8212;</td>
        </tr>
        <tr>
          <td>Cowering</td>
          <td>&#8211;2<sup>1</sup></td>
          <td>&#8211;2<sup>1</sup></td>
        </tr>
        <tr className="odd-row">
          <td>Entangled</td>
          <td>+0<sup>2</sup></td>
          <td>+0<sup>2</sup></td>
        </tr>
        <tr>
          <td>Flat-footed (such as surprised, balancing, climbing)</td>
          <td>+0<sup>1</sup></td>
          <td>+0<sup>1</sup></td>
        </tr>
        <tr className="odd-row">
          <td>Grappling (but attacker is not)</td>
          <td>+0<sup>1</sup></td>
          <td>+0<sup>1,3</sup></td>
        </tr>
        <tr>
          <td>Helpless (such as paralyzed, sleeping, or bound)</td>
          <td>&#8211;4<sup>4</sup></td>
          <td>+0<sup>4</sup></td>
        </tr>
        <tr className="odd-row">
          <td>Kneeling or sitting</td>
          <td>&#8211;2</td>
          <td>+2</td>
        </tr>
        <tr>
          <td>Pinned</td>
          <td>&#8211;4<sup>4</sup></td>
          <td>+0<sup>4</sup></td>
        </tr>
        <tr className="odd-row">
          <td>Prone</td>
          <td>&#8211;4</td>
          <td>+4</td>
        </tr>
        <tr>
          <td>Squeezing through a space</td>
          <td>&#8211;4</td>
          <td>&#8211;4</td>
        </tr>
        <tr className="odd-row">
          <td>Stunned</td>
          <td>&#8211;2<sup>1</sup></td>
          <td>&#8211;2<sup>1</sup></td>
        </tr>
        <tr>
          <td colSpan="3">1 <i>The defender loses any Dexterity
bonus to AC.</i></td>
        </tr>
        <tr>
          <td colSpan="3">2 <i>An entangled character takes a &#8211;4
penalty to Dexterity.</i></td>
        </tr>
        <tr>
          <td colSpan="3">3 <i>Roll randomly to see which grappling
combatant you strike. That defender loses any Dexterity bonus to AC.</i></td>
        </tr>
        <tr>
          <td colSpan="3">4 <i>Treat the defender&#8217;s Dexterity as 0
(&#8211;5 modifier). Rogues can sneak attack helpless or pinned defenders.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="cover"></a><h5>COVER</h5>
    <p className="initial">To determine whether your target has cover
from your ranged attack, choose a corner of your square. If any line
from this corner to any corner of the target&#8217;s square passes through a
square or border that blocks line of effect or provides cover, or
through a square occupied by a creature, the target has cover (+4 to
AC).</p>
    <p>When making a melee attack against an adjacent target, your
target has cover if any line from your square to the target&#8217;s square
goes through a wall (including a low wall). When making a melee attack
against a target that isn&#8217;t adjacent to you (such as with a reach
weapon), use the rules for determining cover from ranged attacks.</p>
    <a id="low-obstacles-and-cover"></a><p><b>Low Obstacles and Cover</b>: A low obstacle (such as a wall
no higher than half your height) provides cover, but only to creatures
within 30 feet (6 squares) of it. The attacker can ignore the cover if
he&#8217;s closer to the obstacle than his target.</p>
    <a id="cover-and-attacks-of-opportunity"></a><p><b>Cover and Attacks of Opportunity</b>: You can&#8217;t execute an
attack of opportunity against an opponent with cover relative to you.</p>
    <a id="cover-and-reflex-saves"></a><p><b>Cover and Reflex Saves</b>: Cover grants you a +2 bonus on
Reflex saves against attacks that originate or burst out from a point
on the other side of the cover from you. Note that spread effects can
extend around corners and thus negate this cover bonus.</p>
    <a id="cover-and-hide-checks"></a><p><b>Cover and Hide Checks</b>: You can use cover to make a Hide
check. Without cover, you usually need concealment (see below) to make
a Hide check.</p>
    <a id="soft-cover"></a><p><b>Soft Cover</b>: Creatures, even your enemies, can provide
you with cover against ranged attacks, giving you a +4 bonus to AC.
However, such soft cover provides no bonus on Reflex saves, nor does
soft cover allow you to make a Hide check.</p>
    <a id="big-creatures-and-cover"></a><p><b>Big Creatures and Cover</b>: Any creature with a space
larger than 5 feet (1 square) determines cover against melee attacks
slightly differently than smaller creatures do. Such a creature can
choose any square that it occupies to determine if an opponent has
cover against its melee attacks. Similarly, when making a melee attack
against such a creature, you can pick any of the squares it occupies to
determine if it has cover against you.</p>
    <a id="total-cover"></a><p><b>Total Cover</b>: If you don&#8217;t have line of effect to your
target he is considered to have total cover from you. You can&#8217;t make an
attack against a target that has total cover.</p>
    <a id="varying-degrees-of-cover"></a><p><b>Varying Degrees of Cover</b>: In some cases, cover may
provide a greater bonus to AC and Reflex saves. In such situations the
normal cover bonuses to AC and Reflex saves can be doubled (to +8 and
+4, respectively). A creature with this improved cover effectively
gains improved evasion against any attack to which the Reflex save
bonus applies. Furthermore, improved cover provides a +10 bonus on Hide
checks.</p>
    <a id="concealment"></a><h5>CONCEALMENT</h5>
    <p className="initial">To determine whether your target has
concealment from your ranged attack, choose a corner of your square. If
any line from this corner to any corner of the target&#8217;s square passes
through a square or border that provides concealment, the target has
concealment.</p>
    <p>When making a melee attack against an adjacent target, your
target has concealment if his space is entirely within an effect that
grants concealment. When making a melee attack against a target that
isn&#8217;t adjacent to you use the rules for determining concealment from
ranged attacks.</p>
    <p>In addition, some magical effects provide concealment against
all attacks, regardless of whether any intervening concealment exists.</p>
    <a id="concealment-miss-chance"></a><p><b>Concealment Miss Chance</b>: Concealment gives the subject
of a successful attack a 20% chance that the attacker missed because of
the concealment. If the attacker hits, the defender must make a miss
chance percentile roll to avoid being struck. Multiple concealment
conditions do not stack.</p>
    <a id="concealment-and-hide-checks"></a><p><b>Concealment and Hide Checks</b>: You can use concealment to
make a Hide check. Without concealment, you usually need cover to make
a Hide check.</p>
    <a id="total-concealment"></a><p><b>Total Concealment</b>: If you have line of effect to a
target but not line of sight he is considered to have total concealment
from you. You can&#8217;t attack an opponent that has total concealment,
though you can attack into a square that you think he occupies. A
successful attack into a square occupied by an enemy with total
concealment has a 50% miss chance (instead of the normal 20% miss
chance for an opponent with concealment).</p>
    <p>You can&#8217;t execute an attack of opportunity against an opponent
with total concealment, even if you know what square or squares the
opponent occupies.</p>
    <a id="ignoring-concealment"></a><p><b>Ignoring Concealment</b>: Concealment isn&#8217;t always
effective. A shadowy area or darkness doesn&#8217;t provide any concealment
against an opponent with darkvision. Characters with low-light vision
can see clearly for a greater distance with the same light source than
other characters. Although invisibility provides total concealment,
sighted opponents may still make Spot checks to notice the location of
an invisible character. An invisible character gains a +20 bonus on
Hide checks if moving, or a +40 bonus on Hide checks when not moving
(even though opponents can&#8217;t see you, they might be able to figure out
where you are from other visual clues).</p>
    <a id="varying-degrees-of-concealment"></a><p><b>Varying Degrees of Concealment</b>: Certain situations may
provide more or less than typical concealment, and modify the miss
chance accordingly.</p>
    <a id="flanking"></a><h5>FLANKING</h5>
    <p className="initial">When making a melee attack, you get a +2
flanking bonus if your opponent is threatened by a character or
creature friendly to you on the opponent&#8217;s opposite border or opposite
corner.</p>
    <p>When in doubt about whether two friendly characters flank an
opponent in the middle, trace an imaginary line between the two
friendly characters&#8217; centers. If the line passes through opposite
borders of the opponent&#8217;s space (including corners of those borders),
then the opponent is flanked.</p>
    <p><i>Exception</i>: If a flanker takes up more than 1 square, it
gets the flanking bonus if any square it occupies counts for flanking.</p>
    <p>Only a creature or character that threatens the defender can
help an attacker get a flanking bonus.</p>
    <p>Creatures with a reach of 0 feet can&#8217;t flank an opponent.</p>
    <a id="helpless-defenders"></a><h5>HELPLESS DEFENDERS</h5>
    <p className="initial">A helpless opponent is someone who is bound,
sleeping, paralyzed, unconscious, or otherwise at your mercy.</p>
    <a id="helpless-defenders-and-regular-attacks"></a><p><b>Regular Attack</b>: A helpless character takes a &#8211;4 penalty
to AC against melee attacks, but no penalty to AC against ranged
attacks. A helpless defender can&#8217;t use any Dexterity bonus to AC. In
fact, his Dexterity score is treated as if it were 0 and his Dexterity
modifier to AC as if it were &#8211;5 (and a rogue can sneak attack him).</p>
    <a id="coup-de-grace"></a><p><b>Coup de Grace</b>: As a full-round action, you can use a
melee weapon to deliver a coup de grace to a helpless opponent. You can
also use a bow or crossbow, provided you are adjacent to the target.
You automatically hit and score a critical hit. If the defender
survives the damage, he must make a Fortitude save (DC 10 + damage
dealt) or die. A rogue also gets her extra sneak attack damage against
a helpless opponent when delivering a coup de grace.</p>
    <p>Delivering a coup de grace provokes attacks of opportunity
from threatening opponents.</p>
    <p>You can&#8217;t deliver a coup de grace against a creature that is
immune to critical hits. You can deliver a coup de grace against a
creature with total concealment, but doing this requires two
consecutive full-round actions (one to &#8220;find&#8221; the creature once you&#8217;ve
determined what square it&#8217;s in, and one to deliver the coup de grace).</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      